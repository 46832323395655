import React, { useState, useEffect } from "react";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Pdf from "react-to-pdf";

function Reports() {
  const ref = React.createRef();

  const [report1, setReport1] = useState({
    bloqMau: "BLOQUES MUNICIPALES",
    fecha: "",
    representante: "",
    mausoleo: "",
    boveda: "",
    bloque: "",
    difunto: "",
    anio_mantenimiento: "",
    valor_cancelar: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReport1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(report1);
  };

  return (
    <>
      <div className="Report">
        <Card>
          <Card.Body>
            <Row>
              <Col md="4">
                <b>Formato: </b>
                <select
                  className="form-control"
                  name="bloqMau"
                  onChange={handleChange}
                >
                  <option value="BLOQUES MUNICIPALES">
                    BLOQUES MUNICIPALES
                  </option>
                  <option value="MAUSOLEOS PARTICULARES">
                    MAUSOLEOS PARTICULARES
                  </option>
                </select>
              </Col>
              <Col md="4">
                <b>Fecha: </b>
                <input
                  type="date"
                  className="form-control"
                  name="fecha"
                  onChange={handleChange}
                />
              </Col>
              <Col md="4">
                <b>Representante: </b>
                <input
                  type="text"
                  className="form-control"
                  name="representante"
                  onChange={handleChange}
                />
              </Col>
              <Col md="4">
                <b>Mausoleo: </b>
                <input
                  type="text"
                  className="form-control"
                  name="mausoleo"
                  onChange={handleChange}
                />
              </Col>
              <Col md="4">
                <b>Bóveda: </b>
                <input
                  type="number"
                  className="form-control"
                  name="boveda"
                  onChange={handleChange}
                />
              </Col>
              <Col md="4">
                <b>Bloque: </b>
                <input
                  type="number"
                  className="form-control"
                  name="bloque"
                  onChange={handleChange}
                />
              </Col>
              <Col md="4">
                <b>Difunto: </b>
                <input
                  type="text"
                  className="form-control"
                  name="difunto"
                  onChange={handleChange}
                />
              </Col>
              <Col md="4">
                <b>Año(s) Mantenimiento: </b>
                <input
                  type="number"
                  className="form-control"
                  name="anio_mantenimiento"
                  onChange={handleChange}
                />
              </Col>
              <Col md="4">
                <b>Valor a Cancelar: </b>
                <input
                  type="number"
                  className="form-control"
                  name="valor_cancelar"
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </Card.Body>
        </Card>
        <Pdf targetRef={ref} filename="code-example.pdf">
          {({ toPdf }) => (
            <button onClick={toPdf} className="btn btn-info text-center">
              Generate Pdf
            </button>
          )}
        </Pdf>
        <Row>
          <Col md="3"></Col>
          <Col md="6">
            <div ref={ref}>
              <Container fluid>
                <Card>
                  <Card.Body>
                    <p className="text-center text-danger">{report1.bloqMau}</p>
                    <p>
                      <b>FECHA:</b> {report1.fecha}
                      <br />
                    </p>
                    <p>
                      <b>REPRESENTANTE:</b> {report1.representante}
                      <br />
                    </p>
                    <p className={report1.bloqMau=="BLOQUES MUNICIPALES"&&"d-none"}>
                      <b>MAUSOLEO:</b> {report1.mausoleo}
                      <br />
                    </p>
                    <p>
                      <b>BOVEDA N°:</b> {report1.boveda}
                      <br />
                    </p>
                    <p>
                      <b>BLOQUE N°:</b> {report1.bloque}
                      <br />
                    </p>
                    <p>
                      <b>DIFUNTO(A):</b> {report1.difunto}
                      <br />
                    </p>
                    <p>
                      <b>AÑO(S) MANTENIMIENTO:</b> {report1.anio_mantenimiento}
                      <br />
                    </p>
                    <p>
                      <b>VALOR A CANCELAR :</b> {report1.valor_cancelar}
                      <br />
                    </p>
                    <Row>
                      <Col md="6" className="text-center">
                        <img
                          width="30%"
                          src={require("assets/img/escudo.jpg").default}
                          alt="..."
                        />
                      </Col>
                      <Col md="6" className="text-center">
                        ____________________
                        <br />
                        Firma
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Container>
            </div>
          </Col>
          <Col md="3"></Col>
        </Row>
      </div>
    </>
  );
}

export default Reports;
