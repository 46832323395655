import React, { Component } from "react";
import axios from "axios";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";
//const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";

class DifuntoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      difunto: {},
      representantes: [],
      pagos: [],
      files: [],
    };
    this.handleDifuntoClick = this.handleDifuntoClick.bind(this);
  }

  componentWillReceiveProps() {
    console.log(this.props);
    axios
      .get(
          url_base + "difuntos.php?id_tumba=" +
          this.props.tumba
      )
      .then((result) => {
        console.log(result.data);
        this.setState({
          difunto: result.data,
        });
      })
      .catch(console.log);

    axios
      .get(
        url_base + "representantes.php?id_tumba=" +
          this.props.tumba
      )
      .then((result1) => {
        console.log(result1.data);
        this.setState({
          representantes: result1.data,
        });
      })
      .catch(console.log);

    axios
      .get(
        url_base + "pagos.php?id_tumba=" +
          this.props.tumba
      )
      .then((result2) => {
        console.log(result2.data);
        this.setState({
          pagos: result2.data,
        });
      })
      .catch(console.log);

    axios
      .get(
        url_base + "files.php?id_tumba=" +
          this.props.tumba
      )
      .then((result3) => {
        console.log(result3.data);
        this.setState({
          files: result3.data,
        });
      })
      .catch(console.log);
  }

  handleDifuntoClick(e) {
    e.preventDefault();
    console.log(e.target.id);
  }

  render() {
    const handleDifuntoClick = this.handleDifuntoClick;
    return (
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Información del difunto</Card.Title>
              <p className="card-category"></p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Col lg="12" sm="12">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="12">
                        <div className="numbers">
                          <Row>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Es niño: </b>{" "}
                                {this.state.difunto.es_nino == 1 ? "SI" : "NO"}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Nombre: </b> {this.state.difunto.nombre}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Cédula: </b> {this.state.difunto.cedula}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Edad: </b> {this.state.difunto.edad}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Fecha de Nacimiento: </b>{" "}
                                {this.state.difunto.fe_nacimiento}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Fecha de Traslado: </b>{" "}
                                {this.state.difunto.fe_translado}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Hora de Inhumación: </b>{" "}
                                {this.state.difunto.hora_inhumacion}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Médico: </b> {this.state.difunto.medico}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Causa de Muerte: </b>{" "}
                                {this.state.difunto.causa}
                              </p>
                            </Col>
                            <Col lg="4" xs="4">
                              <p className="card-category text-left">
                                <b>Fecha de Compra: </b>{" "}
                                {this.state.difunto.fe_compra}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <p className="card-category text-center"></p>
                  </Card.Footer>
                </Card>
              </Col>
              <Col md="12">
                <Card className="card-plain table-plain-bg">
                  <Card.Header>
                    <Card.Title as="h5">Información Representante</Card.Title>
                    <p className="card-category"></p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover">
                      <thead>
                        <tr>
                          <th className="border-0">#</th>
                          <th className="border-0">Nombre</th>
                          <th className="border-0">Cédula</th>
                          <th className="border-0">Parentesco</th>
                          <th className="border-0">Dirección</th>
                          <th className="border-0">Teléfono</th>
                          <th className="border-0">Descripción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.representantes.map((r) => (
                          <tr key={r.id}>
                            <td>{r.id}</td>
                            <td>{r.nombre}</td>
                            <td>{r.cedula}</td>
                            <td>{r.parentesco}</td>
                            <td>{r.direccion}</td>
                            <td>{r.telefono}</td>
                            <td>{r.descripcion}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="12">
                <Card className="card-plain table-plain-bg">
                  <Card.Header>
                    <Card.Title as="h5">Información Pagos</Card.Title>
                    <p className="card-category"></p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover">
                      <thead>
                        <tr>
                          <th className="border-0">Detalle</th>
                          <th className="border-0">Valor</th>
                          <th className="border-0">Estado</th>
                          <th className="border-0">No Recibo</th>
                          <th className="border-0">Fecha Vencimiento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.pagos.map((p) => (
                          <tr key={p.id}>
                            <td>{p.detalle}</td>
                            <td>{p.valor}</td>
                            <td>{p.estado}</td>
                            <td>{p.recibo}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="12">
                <Card className="card-plain table-plain-bg">
                  <Card.Header>
                    <Card.Title as="h5">Documentos</Card.Title>
                    <p className="card-category"></p>
                  </Card.Header>
                  <Card.Body className="table-full-width table-responsive px-0">
                    <Table className="table-hover">
                      <thead>
                        <tr>
                          <th className="border-0">Documento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.files.map((f) => (
                          <tr>
                            <td>
                              <a href={url_base + "docs/"+f}><i className="fas fa-file-download"></i> {f}</a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default DifuntoContainer;
