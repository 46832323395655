import React, { Component } from "react";
import axios from "axios";
import DifuntoContainer from './DifuntoContainer';
import $ from "jquery";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";

class TumbasContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tumbas: [],
      tumbaSelec: 0
    };
    this.handleTumbaClick = this.handleTumbaClick.bind(this);
  }

  componentWillReceiveProps() {
    console.log(this.props);
    axios
      .get(
        url_base + "tumbas.php?id_bloque=" + this.props.bloque
      )
      .then((result) => {
        console.log(result.data);
        this.setState({
          tumbas: result.data,
        });
      })
      .catch(console.log);
  }

  handleTumbaClick(e) {
    e.preventDefault();
    console.log(e.target.id);
    this.setState({
      tumbaSelec: e.target.id
    });
  }

  peticionDeleteBloque = async (id) => {
    await $.ajax({
      type: "POST",
      url: url_base + "bloques.php?id=" + id,
      data: {
        METHOD: "DELETE",
      },
      success: function (response, statusText, xhr) {
        console.log(response);
        window.location.reload();
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  eliminarBloque = () => {
    if (this.props.bloque > 0) {
      if (window.confirm("¿Esta seguro de eliminar el bloque: " + this.props.bloque)) {
        this.peticionDeleteBloque(this.props.bloque);
      }
    }
    else {
      alert("Seleccione un bloque");
    }
  };



  render() {
    const handleTumbaClick = this.handleTumbaClick;
    const eliminarBloque = this.eliminarBloque;
    return (
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">
                Bloque Seleccionado {this.props.bloque}
                <br />
                <Button onClick={eliminarBloque} className="pull-right btn-sm btn-danger">Eliminar Todo el Bloque</Button>
              </Card.Title>
              <p className="card-category">Bovedas</p>

            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Row>
                {this.state.tumbas.map((t) => (
                  <Col lg="3" sm="6" key={t.id}>
                    <Card className={"card-stats " + t.bg}>
                      <Card.Body>
                        <Row>
                          <Col xs="12">
                            <div className="numbers text-light">
                              <p className={"card-category text-center " + t.txt}>
                                <a href="" id={t.id} onClick={handleTumbaClick}>{t.id}</a>
                              </p>
                              <p className={"card-category text-center " + t.txt}>
                                Bloque {t.id_bloque}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <p className={"card-category text-center " + t.txt}>
                          {t.estado}
                        </p>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <DifuntoContainer tumba={this.state.tumbaSelec} />
      </Row>
    );
  }
}

export default TumbasContainer;
