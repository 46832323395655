import React, { useState, useEffect } from "react";
import Fontawesome from "@fortawesome/fontawesome-free";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  DropdownButton,
} from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import MaterialTable from "material-table";
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import axios from "axios";

import $ from "jquery";
import "jquery-validation";

import { Modal } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";

const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";
//const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";

function Difuntos() {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columnas = [
    {
      title: "Nombre",
      field: "nombre",
    },
    {
      title: "Cedula",
      field: "cedula",
    },
    {
      title: "Fecha de traslado",
      field: "fe_translado",
    },
  ];

  const modalStyle1 = {
    position: "absolute",
    margin: "5%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  };

  const [data, setData] = useState([]);
  const [tumbas, setTumbas] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [difuntoNuevo, setDifuntoNuevo] = useState({
    cambio_nombre: "",
    causa: "",
    cedula: "",
    edad: "",
    es_nino: "",
    fe_compra: "",
    fe_nacimiento: "",
    fe_translado: "",
    hora_inhumacion: "",
    medico: "",
    nombre: "",
  });
  const [representantes, setRepresentantes] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [files, setFiles] = useState([]);
  const [userType, setUserType] = useState("2");
  const [valido1, setValido1] = useState(false);
  const [valido2, setValido2] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDifuntoNuevo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const peticionGet = async () => {
    await axios
      .get(url_base + "difuntos.php")
      .then((result) => {
        setData(result.data);
      })
      .catch(console.log);

    await axios
      .get(url_base + "tumbas.php?id_cementerio=1")
      .then((result) => {
        console.log(result.data);
        setTumbas(result.data);
      })
      .catch(console.log);

    await axios
      .get(url_base + "session.php")
      .then((result) => {
        console.log(result.data);
        if (result.data.user) {
          setUserType(result.data.user.tipo)
        }
        else {
          window.location.replace("https://cementerio.huaca.gob.ec/login.php");
        }
      })
      .catch(console.log);
  };

  const getRepresentantes = async (id_difunto) => {
    await axios
      .get(url_base + "representantes.php?id_difunto=" + id_difunto)
      .then((result) => {
        setRepresentantes(result.data);
      })
      .catch(console.log);
  };

  const getPagos = async (id_difunto) => {
    await axios
      .get(url_base + "pagos.php?id_difunto=" + id_difunto)
      .then((result) => {
        setPagos(result.data);
      })
      .catch(console.log);
  };

  const getFiles = async (id_difunto) => {
    await axios
      .get(url_base + "files.php?id_difunto=" + id_difunto)
      .then((result) => {
        setFiles(result.data);
      })
      .catch(console.log);
  };

  const peticionPost = async () => {
    $("#myForm").validate();

    await $.ajax({
      type: "POST",
      url: $("#myForm").attr("action"),
      data: $("#myForm").serialize(),
      success: function (response, statusText, xhr) {
        console.log(response);
        peticionGet();
        setModalEditar(false);
        setModalInsertar(false);
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  const peticionPostRep = async () => {
    $("#formRepresentante").validate();

    await $.ajax({
      type: "POST",
      url: $("#formRepresentante").attr("action"),
      data: $("#formRepresentante").serialize(),
      //or your custom data either as object {foo: "bar", ...} or foo=bar&...
      success: function (response, statusText, xhr) {
        response = JSON.parse(response);
        getRepresentantes(response.id_difunto);
        $(".new-val").val("");
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  const peticionPostPagos = async () => {
    $("#formPagos").validate();

    await $.ajax({
      type: "POST",
      url: $("#formPagos").attr("action"),
      data: $("#formPagos").serialize(),
      //or your custom data either as object {foo: "bar", ...} or foo=bar&...
      success: function (response, statusText, xhr) {
        response = JSON.parse(response);
        getPagos(response.id_difunto);
        $(".new-val").val("");
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  const peticionPostFile = async () => {
    var num = $("#fileArchIngEgr").prop("files").length;

    for (var i = 0; i < num; i++) {
      var imagenes = $("#fileArchIngEgr").prop("files")[i];
      var formData = new FormData();
      formData.append("codigo", difuntoNuevo.id);
      formData.append("imagenes", imagenes);

      $.ajax({
        url: url_base + "upload.php",
        type: "POST",
        contentType: false,
        data: formData,
        processData: false,
        success: function (datos) {
          getFiles(difuntoNuevo.id);
          $(".new-val").val("");
        },
      });
    }
  };

  const peticionDeleteRep = async (id) => {
    await $.ajax({
      type: "POST",
      url: url_base + "representantes.php?id=" + id,
      data: {
        METHOD: "DELETE",
      },
      success: function (response, statusText, xhr) {
        getRepresentantes(difuntoNuevo.id);
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  const peticionDeletePago = async (id) => {
    await $.ajax({
      type: "POST",
      url: url_base + "pagos.php?id=" + id,
      data: {
        METHOD: "DELETE",
      },
      success: function (response, statusText, xhr) {
        getPagos(difuntoNuevo.id);
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  const peticionDeleteFile = async (name) => {
    await $.ajax({
      type: "POST",
      url: url_base + "files.php?name=" + name,
      data: {
        METHOD: "DELETE",
      },
      success: function (response, statusText, xhr) {
        getFiles(difuntoNuevo.id);
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  const peticionDeleteDifunto = async (id, id_tumba) => {
    await $.ajax({
      type: "POST",
      url: url_base + "difuntos.php?id=" + id + "&id_tumba=" + id_tumba,
      data: {
        METHOD: "DELETE",
      },
      success: function (response, statusText, xhr) {
        console.log(response);
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const seleccionarDifunto = (difunto, caso) => {
    getRepresentantes(difunto.id);
    getPagos(difunto.id);
    getFiles(difunto.id);
    setDifuntoNuevo(difunto);
    caso === "Editar" && abrirCerrarModalEditar();
  };

  const eliminarDifunto = (difunto) => {
    if (window.confirm(difunto.nombre)) {
      peticionDeleteDifunto(difunto.id, difunto.id_tumba);
      peticionGet();
    }
  };


  useEffect(() => {
    peticionGet();
  }, []);

  const validarCedula = (cedula) => {
    console.log(cedula);
    var total = 0;
    var longitud = cedula.length;
    var longcheck = longitud - 1;
    var i;
    if (cedula !== "" && longitud === 10) {
      for (i = 0; i < longcheck; i++) {
        if (i % 2 === 0) {
          var aux = cedula.charAt(i) * 2;
          if (aux > 9) aux -= 9;
          total += aux;
        } else {
          total += parseInt(cedula.charAt(i)); // parseInt o concatenará en lugar de sumar
        }
      }

      total = total % 10 ? 10 - total % 10 : 0;

      if (cedula.charAt(longitud - 1) == total) {
        console.log("Cedula Válida");
        return true;
      } else {
        alert("Cedula Inválida");
        return false;
      }
    }
    else {
      return false;
    }
  }

  const validarFechaMenorActual = (date) => {
    console.log(date);
    var x = new Date(date);
    console.log(x);
    var today = new Date();
    console.log(today);

    if (x >= today) {
      alert("Fecha Incorrecta");
        return false;
    }
    else {
      console.log("Fecha Correcta");
        return true;
    }
  }

  const bodyInsertar = (
    <Row>
      <Col md="12">
        <form id="myForm" method="post" action={url_base + "difuntos.php"}>
          <Card>
            <Card.Body>
              <Row>
                <Col md="12">
                  <input
                    type="text"
                    defaultValue="POST"
                    name="METHOD"
                    className="d-none"
                  />
                  <Form.Group>
                    <label>nombre</label>
                    <Form.Control
                      placeholder="Nombre Difunto"
                      type="text"
                      name="nombre"
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <label>Boveda</label>
                    <select className="form-control" name="id_tumba">
                      {tumbas.map((t) => (
                        <option key={t.id} value={t.id}>
                          {"BLOQUE: " +
                            t.id_bloque +
                            " - TIPO: " +
                            t.tipo +
                            " - BOVEDA: " +
                            t.id}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <label>Estado</label>
                    <select className="form-control" name="estado">
                      <option value="Vendido">Vendido</option>
                      <option value="Arrendado">Arrendado</option>
                      <option value="Donado">Donado</option>
                      <option value="A Plazos">A Plazos</option>
                    </select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <label>Cédula</label>
                    <Form.Control
                      placeholder="Cédula Difunto"
                      type="text"
                      name="cedula"
                      onChange={(e) => setValido1(validarCedula(e.target.value))}
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <label>Edad</label>
                    <Form.Control
                      placeholder="Edad Difunto"
                      type="number"
                      name="edad"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <label>Fecha de Fallecimiento</label>
                    <Form.Control
                      type="date"
                      name="fe_nacimiento"
                      onChange={(e) => {
                        setValido2(validarFechaMenorActual(e.target.value));
                        console.log(valido2);
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <label>Fecha de Translado</label>
                    <Form.Control
                      type="date"
                      name="fe_translado"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <label>Médico que Declara la Muerte</label>
                    <Form.Control
                      placeholder="Médico"
                      type="text"
                      name="medico"
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <label>Causa de la muerte</label>
                    <Form.Control
                      type="text"
                      placeholder="Causa"
                      name="causa"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <label>Es ni&ntilde;o / adulto</label>
                    <select className="form-control" name="es_nino">
                      <option value={true}>SI</option>
                      <option value={false}>NO</option>
                    </select>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <label>Hora inhumaci&oacute;n</label>
                    <Form.Control
                      type="time"
                      placeholder="Hora"
                      name="hora_inhumacion"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <label>Cambio de Nombre</label>
                    <Form.Control
                      placeholder="Cambio Nombre Difunto"
                      type="text"
                      name="cambio_nombre"
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group>
                    <label>Fecha de Compra</label>
                    <Form.Control type="date" name="fe_compra"></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="6" className="text-center">
                  <Button
                    className="btn-fill pull-right"
                    variant="info"
                    onClick={() => peticionPost()}
                    disabled={!(valido1&&valido2)}
                  >
                    Guardar
                  </Button>
                </Col>

                <Col md="6" className="text-center">
                  <Button
                    className="btn-fill pull-left"
                    variant="warning"
                    onClick={() => abrirCerrarModalInsertar()}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </form>
      </Col>
    </Row>
  );

  const bodyEditar = (
    <Row>
      <Col md="12">
        <Card>
          <Card.Body>
            <Tabs defaultActiveKey="difunto">
              <Tab eventKey="difunto" title="Difunto">
                <Card>
                  <Card.Body>
                    <form
                      id="myForm"
                      method="post"
                      action={url_base + "difuntos.php"}
                    >
                      <Row>
                        <Col md="12">
                          <input
                            type="text"
                            defaultValue="PUT"
                            name="METHOD"
                            className="d-none"
                          />
                          <input
                            type="text"
                            name="id"
                            className="d-none"
                            defaultValue={difuntoNuevo && difuntoNuevo.id}
                          />
                          <Form.Group>
                            <label>nombre</label>
                            <Form.Control
                              placeholder="Nombre Difunto"
                              type="text"
                              name="nombre"
                              defaultValue={difuntoNuevo && difuntoNuevo.nombre}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <label>Es ni&ntilde;o / adulto</label>
                            <select
                              defaultValue={
                                difuntoNuevo && difuntoNuevo.id_tumba
                              }
                              className="form-control"
                              name="id_tumba"
                            >
                              {tumbas.map((t) => (
                                <option key={t.id} value={t.id}>
                                  {"BLOQUE: " +
                                    t.id_bloque +
                                    " - TIPO: " +
                                    t.tipo +
                                    " - BOVEDA: " +
                                    t.id}
                                </option>
                              ))}
                            </select>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <label>Estado</label>
                            <select className="form-control" name="estado" >
                              <option value="Vendido">Vendido</option>
                              <option value="Arrendado">Arrendado</option>
                              <option value="Donado">Donado</option>
                              <option value="A Plazos">A Plazos</option>
                            </select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <label>Cédula</label>
                            <Form.Control
                              placeholder="Cédula Difunto"
                              type="text"
                              name="cedula"
                              defaultValue={difuntoNuevo && difuntoNuevo.cedula}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group>
                            <label>Edad</label>
                            <Form.Control
                              placeholder="Edad Difunto"
                              type="number"
                              name="edad"
                              defaultValue={difuntoNuevo && difuntoNuevo.edad}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <label>Fecha de Fallecimiento</label>
                            <Form.Control
                              type="date"
                              name="fe_nacimiento"
                              defaultValue={
                                difuntoNuevo && difuntoNuevo.fe_nacimiento
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group>
                            <label>Fecha de Translado</label>
                            <Form.Control
                              type="date"
                              name="fe_translado"
                              defaultValue={
                                difuntoNuevo && difuntoNuevo.fe_translado
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <label>Médico que Declara la Muerte</label>
                            <Form.Control
                              placeholder="Médico"
                              type="text"
                              name="medico"
                              defaultValue={difuntoNuevo && difuntoNuevo.medico}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group>
                            <label>Causa de la muerte</label>
                            <Form.Control
                              type="text"
                              placeholder="Causa"
                              name="causa"
                              defaultValue={difuntoNuevo && difuntoNuevo.causa}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <label>Es ni&ntilde;o / adulto</label>
                            <select
                              className="form-control"
                              name="es_nino"
                              defaultValue={
                                difuntoNuevo && difuntoNuevo.es_nino
                              }
                            >
                              <option defaultValue={true}>SI</option>
                              <option defaultValue={false}>NO</option>
                            </select>
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group>
                            <label>Hora inhumaci&oacute;n</label>
                            <Form.Control
                              type="time"
                              placeholder="Hora"
                              name="hora_inhumacion"
                              defaultValue={
                                difuntoNuevo && difuntoNuevo.hora_inhumacion
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <label>Cambio de Nombre</label>
                            <Form.Control
                              placeholder="Cambio Nombre Difunto"
                              type="text"
                              name="cambio_nombre"
                              defaultValue={
                                difuntoNuevo && difuntoNuevo.cambio_nombre
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group>
                            <label>Fecha de Compra</label>
                            <Form.Control
                              type="date"
                              name="fe_compra"
                              defaultValue={
                                difuntoNuevo && difuntoNuevo.fe_compra
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6" className="text-center">
                          <Button
                            className="btn-fill pull-right"
                            variant="info"
                            onClick={() => peticionPost()}
                          >
                            Guardar
                          </Button>
                        </Col>

                        <Col md="6" className="text-center">
                          <Button
                            className="btn-fill pull-left"
                            variant="warning"
                            onClick={() => abrirCerrarModalEditar()}
                          >
                            Cancelar
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="representantes" title="Representantes">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <Card className="">
                          <Card.Header>
                            <Card.Title as="h4">
                              Información Representante
                            </Card.Title>
                            <p className="card-category">
                              {difuntoNuevo.nombre}
                            </p>
                          </Card.Header>
                          <Card.Body className="table-full-width table-responsive px-0">
                            <form
                              id="formRepresentante"
                              method="post"
                              action={url_base + "representantes.php"}
                            >
                              <Table className="table-hover">
                                <thead>
                                  <tr>
                                    <th className="border-0">#</th>
                                    <th className="border-0">Nombre</th>
                                    <th className="border-0">Cédula</th>
                                    <th className="border-0">Parentesco</th>
                                    <th className="border-0">Dirección</th>
                                    <th className="border-0">Teléfono</th>
                                    <th className="border-0">Descripción</th>
                                    <th className="border-0"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {representantes.map((m) => (
                                    <tr>
                                      <td>{m.id}</td>
                                      <td>{m.nombre}</td>
                                      <td>{m.cedula}</td>
                                      <td>{m.parentesco}</td>
                                      <td>{m.direccion}</td>
                                      <td>{m.telefono}</td>
                                      <td>{m.descripcion}</td>
                                      <td className="text-center">
                                        <Button
                                          className="btn-simple btn-link p-1"
                                          type="button"
                                          variant="danger"
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                "Seguro desea eliminar el representante?"
                                              )
                                            ) {
                                              peticionDeleteRep(m.id);
                                            }
                                          }}
                                        >
                                          <i className="fas fa-times"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td>
                                      <input
                                        name="id_difunto"
                                        type="text"
                                        defaultValue={
                                          difuntoNuevo && difuntoNuevo.id
                                        }
                                        className="form-control d-none"
                                        readOnly
                                      />
                                      <input
                                        name="METHOD"
                                        type="text"
                                        defaultValue="POST"
                                        className="d-none"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="nombre"
                                        type="text"
                                        className="form-control new-val"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cedula"
                                        type="text"
                                        className="form-control new-val"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="parentesco"
                                        type="text"
                                        className="form-control new-val"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="direccion"
                                        type="text"
                                        className="form-control new-val"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="telefono"
                                        type="text"
                                        className="form-control new-val"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="descripcion"
                                        type="text"
                                        className="form-control new-val"
                                      />
                                    </td>
                                    <td className="text-center">
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-506045838">
                                            Eliminar
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          className="btn-simple btn-link p-1"
                                          type="button"
                                          variant="dark"
                                          onClick={() => peticionPostRep()}
                                        >
                                          <i className="far fa-save"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="pagos" title="Pagos">
                <Card>
                  <Card.Body>
                    <Container fluid>
                      <Row>
                        <Col md="12">
                          <Card className="card-plain table-plain-bg">
                            <Card.Header>
                              <Card.Title as="h4">Información Pagos</Card.Title>
                              <p className="card-category">
                                {difuntoNuevo.nombre}
                              </p>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                              <form
                                id="formPagos"
                                method="post"
                                action={url_base + "pagos.php"}
                              >
                                <Table className="table-hover">
                                  <thead>
                                    <tr>
                                      <th className="border-0">#</th>
                                      <th className="border-0">Detalle</th>
                                      <th className="border-0">Valor</th>
                                      <th className="border-0">Estado</th>
                                      <th className="border-0">Recibo</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pagos.map((p) => (
                                      <tr>
                                        <td>{p.id}</td>
                                        <td>{p.detalle}</td>
                                        <td>{p.valor}</td>
                                        <td>
                                          {(p.estado == "0" && "No Pagado") ||
                                            (p.estado == "1" && "Pagado")}
                                        </td>
                                        <td>{p.recibo}</td>
                                        <td className="text-center">
                                          <Button
                                            className="btn-simple btn-link p-1"
                                            type="button"
                                            variant="danger"
                                            onClick={() => {
                                              if (
                                                window.confirm(
                                                  "Seguro desea eliminar el pago?"
                                                )
                                              ) {
                                                peticionDeletePago(p.id);
                                              }
                                            }}
                                          >
                                            <i className="fas fa-times"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td>
                                        <input
                                          name="id_difunto"
                                          type="text"
                                          defaultValue={
                                            difuntoNuevo && difuntoNuevo.id
                                          }
                                          className="form-control d-none"
                                          readOnly
                                        />
                                        <input
                                          name="METHOD"
                                          type="text"
                                          defaultValue="POST"
                                          className="d-none"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control new-val"
                                          name="detalle"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control new-val"
                                          name="valor"
                                        />
                                      </td>
                                      <td>
                                        <select
                                          type="text"
                                          className="form-control new-val"
                                          name="estado"
                                        >
                                          <option value="0">No pagado</option>
                                          <option value="1">Pagado</option>
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control new-val"
                                          name="recibo"
                                        />
                                      </td>
                                      <td className="text-center">
                                        <Button
                                          className="btn-simple btn-link p-1"
                                          type="button"
                                          variant="dark"
                                          onClick={() => peticionPostPagos()}
                                        >
                                          <i className="far fa-save"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </form>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="documentos" title="Documentos">
                <Card>
                  <Card.Body>
                    <Container fluid>
                      <Row>
                        <Col md="12">
                          <Card className="card-plain table-plain-bg">
                            <Card.Header>
                              <Card.Title as="h4">Documentos</Card.Title>
                              <p className="card-category">
                                Here is a subtitle for this table
                              </p>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                              <form
                                id="formFiles"
                                method="post"
                                enctype="multipart/form-data"
                                action={url_base + "usuarios1.php"}
                              ></form>
                              <Table className="table-hover">
                                <thead>
                                  <tr>
                                    <th className="border-0">Documento</th>
                                    <th className="border-0"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {files.map((f) => (
                                    <tr key={f}>
                                      <td>
                                        <a href={url_base + "docs/" + f}>
                                          <i className="fas fa-file-download"></i>{" "}
                                          {f}
                                        </a>
                                      </td>
                                      <td className="text-center">
                                        <Button
                                          className="btn-simple btn-link p-1"
                                          type="button"
                                          variant="danger"
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                "Seguro desea eliminar el archivo?"
                                              )
                                            ) {
                                              peticionDeleteFile(f);
                                            }
                                          }}
                                        >
                                          <i className="fas fa-times"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>

                              <Row>
                                <Col md="8">
                                  <form
                                    id="frmSubirArchivos"
                                    method="post"
                                    enctype="multipart/form-data"
                                  >
                                    <input
                                      type="file"
                                      multiple="multiple"
                                      className="form-control-file new-val"
                                      id="fileArchIngEgr"
                                      name="fileArchIngEgr[]"
                                    />
                                  </form>
                                </Col>
                                <Col md="4">
                                  <Button
                                    className="btn btn-info p-1"
                                    type="button"
                                    variant="dark"
                                    onClick={() => peticionPostFile()}
                                  >
                                    <i class="far fa-save"></i>&nbsp; Subir
                                    Documentos
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  return (
    <>
      <Container fluid>
        <Row className={userType != "1" && "d-none"}>
          <Col className="text-center mb-200">
            <Button
              onClick={() => abrirCerrarModalInsertar()}
              className="form-control btn-fill pull-right"
              type="button"
              variant="info"
            >
              <i className="far fa-plus-square"></i>
              Agregar Difunto
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <MaterialTable
              title="Difuntos"
              icons={tableIcons}
              columns={columnas}
              data={data}
              actions={[
                {
                  icon: forwardRef((props, ref) => (
                    <Edit {...props} ref={ref} />
                  )),
                  tooltip: "Editar Difunto",
                  onClick: (event, rowData) => {
                    if (userType != "1") {
                      seleccionarDifunto(rowData, "Editar")
                      alert("Sin Permisos");
                    }
                    else {
                      seleccionarDifunto(rowData, "Editar")
                    }
                  },
                },
                {
                  icon: forwardRef((props, ref) => (
                    <DeleteOutline {...props} ref={ref} />
                  )),
                  tooltip: "Eliminar Difunto",
                  onClick: (event, rowData) => {
                    // if(userType!="1") {
                    //   alert("Sin Permisos");
                    // }
                    // else{
                    eliminarDifunto(rowData);
                    //}
                  },
                },
              ]}
              options={{
                actionsColumnIndex: -1,
              }}
            />
          </Col>
        </Row>
        <br />
      </Container>
      <Modal
        open={modalInsertar}
        onClose={abrirCerrarModalInsertar}
        style={modalStyle1}
      >
        {bodyInsertar}
      </Modal>
      <Modal
        open={modalEditar}
        onClose={abrirCerrarModalEditar}
        style={modalStyle1}
      >
        {bodyEditar}
      </Modal>
    </>
  );
}

export default Difuntos;
