/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.huaca.gob.ec/gadhuaca//product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.huaca.gob.ec/gadhuaca/)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";

import Mausoleos from "views/Mausoleos.js";
import Bovedas from "views/Bovedas.js";
import Jardineras from "views/Jardineras.js";
import FormDifunto from "views/FormDifunto.js";
import Reports from "views/Reports.js";
import Users from "views/Users.js";

const dashboardRoutes = [
  {
    path: "/mausoleos",
    name: "Mausoleos",
    icon: "nc-icon nc-istanbul",
    component: Mausoleos,
    layout: "/admin",
  },
  {
    path: "/bovedas",
    name: "Bloques Municipales",
    icon: "nc-icon nc-tablet-2",
    component: Bovedas,
    layout: "/admin",
  },
  {
    path: "/jardineras",
    name: "Jardineras",
    icon: "nc-icon nc-grid-45",
    component: Jardineras,
    layout: "/admin",
  },
  {
    path: "/FormDifunto",
    name: "Difuntos",
    icon: "nc-icon nc-single-02",
    component: FormDifunto,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reportes",
    icon: "nc-icon nc-paper-2",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: "nc-icon nc-badge",
    component: Users,
    layout: "/admin",
  }
];

export default dashboardRoutes;
