import React, { Component } from "react";
import axios from "axios";
import TumbasContainer from "./TumbasContainer";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import $ from "jquery";

const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";
//const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";

class BovedasContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bloques: [],
      bloqueSelec: 0,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    axios
      .get(url_base + "bloques.php?tipo=boveda")
      .then((result) => {
        console.log(result.data);
        this.setState({
          bloques: result.data,
        });
      })
      .catch(console.log);
  }

  handleClick(e) {
    e.preventDefault();
    console.log(e.target.id);
    this.setState({
      bloqueSelec: e.target.id,
    });
  }

  addBloque() {
    $.ajax({
      type: "POST",
      url: $("#formAddBloque").attr("action"),
      data: $("#formAddBloque").serialize(),
      success: function (response, statusText, xhr) {
        console.log(response);
        () => getBloques();
        window.location.reload();
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  }

  getBloques = async () => {
    await axios
    .get(url_base + "bloques.php?tipo=boveda")
    .then((result) => {
      console.log(result.data);
      this.setState({
        bloques: result.data,
      });
    })
    .catch(console.log);
  }

  render() {
    const handleClick = this.handleClick;
    const addBloque = this.addBloque;
    return (
      <div>
        <Row>
          {this.state.bloques.map((bloque) => (
            <Col lg="3" sm="6" key={bloque.id}>
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <div className="numbers text-light">
                        <p className="card-category text-center">
                          <b>
                            <a href="" id={bloque.id} onClick={handleClick}>
                              Bloque No. {bloque.id}
                            </a>
                          </b>
                        </p>
                        <p className="card-category text-center">
                          {bloque.fila} x {bloque.columna}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <p className="card-category text-center">{bloque.tipo}</p>
                </Card.Footer>
              </Card>
            </Col>
          ))}
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <form
                  id="formAddBloque"
                  method="post"
                  action={url_base + "bloques.php"}
                >
                  <Row>
                    <Col xs="12">
                      <div className="numbers text-light">
                        <p className="card-category text-center">
                          <b>Nuevo Bloque</b>
                        </p>
                        <p className="card-category text-center">
                          <Row>
                            <Col md="6">
                              <input
                                name="fila"
                                type="text"
                                className="form-control"
                                placeholder="Filas"
                              />
                              <input
                                name="METHOD"
                                type="text"
                                className="d-none"
                                value="POST"
                              />
                              <input
                                name="tipo"
                                type="text"
                                className="d-none"
                                value="boveda"
                              />
                              <input
                                name="id_cementerio"
                                type="text"
                                className="d-none"
                                value="1"
                              />
                            </Col>
                            <Col md="6">
                              <input
                                name="columna"
                                type="text"
                                className="form-control"
                                placeholder="Columnas"
                              />
                            </Col>
                          </Row>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <p className="card-category text-center">
                  <Button className="btn-sm" onClick={addBloque}>
                    Agregar Bloque
                  </Button>
                </p>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <TumbasContainer bloque={this.state.bloqueSelec} />
      </div>
    );
  }
}

export default BovedasContainer;
