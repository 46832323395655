import React, { useState, useEffect } from "react";

import axios from "axios";

import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import MausoleosContainer from "../components/cementerio/MausoleosContainer";

const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";

function Mausoleos() {

  const peticionGet = async () => {
    await axios
      .get(url_base + "session.php")
      .then((result) => {
        console.log(result.data);
        if (result.data.user) {
        }
        else {
          window.location.replace("https://cementerio.huaca.gob.ec/login.php");
        }
      })

      .catch(console.log);
  };


  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm="6">
            <Row>
              Eliminar bloque: N°
              <Col sm="2">
                <input type="number" className="form-control" />
              </Col>
              <Col sm="6">
                <Button className="btn-sm btn-danger">OK</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <MausoleosContainer />
      </Container>
    </>
  );
}

export default Mausoleos;
