import React, { useState, useEffect } from "react";

import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import axios from "axios";

import JardinerasContainer from "../components/cementerio/JardinerasContainer";


const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";

function Jardineras() {

  const peticionGet = async () => {
    await axios
          .get(url_base + "session.php")
          .then((result) => {
            console.log(result.data);
            if(result.data.user){
            }
            else{
              window.location.replace("https://cementerio.huaca.gob.ec/login.php");
            }
          })
          
          .catch(console.log);
  };

  
  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <>
    <Container fluid>
      <JardinerasContainer />
    </Container>
    </>
  );
}

export default Jardineras;
