import React, { useState, useEffect } from "react";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import axios from "axios";

import $ from "jquery";


const url_base = "https://cementerio.huaca.gob.ec/api_cementerio/";

function Users() {


  const [users, setUsers] = useState([]);

  useEffect(() => {
    peticionGet();
  }, []);

  const peticionGet = async () => {
    await axios
      .get(url_base + "usuarios.php")
      .then((result) => {
        setUsers(result.data);
        console.log(result.data);
        console.log(users);
      })
      .catch(console.log);

    await axios
      .get(url_base + "session.php")
      .then((result) => {
        console.log(result.data);
        if (result.data.user) {
        }
        else {
          window.location.replace("https://cementerio.huaca.gob.ec/login.php");
        }
      })

      .catch(console.log);
  };

  const peticionPostUser = async () => {

    await $.ajax({
      type: "POST",
      url: $("#formUser").attr("action"),
      data: $("#formUser").serialize(),
      success: function (response, statusText, xhr) {
        console.log(response);
        response = JSON.parse(response);
        $(".new-val").val("");
        peticionGet();
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  const peticionDeleteUser = async (id) => {
    await $.ajax({
      type: "POST",
      url: url_base + "usuarios.php?id=" + id,
      data: {
        METHOD: "DELETE",
      },
      success: function (response, statusText, xhr) {
        peticionGet();
      },
      error: function (request, status, error) {
        alert(request.responseText);
      },
    });
  };

  return (
    <>
      <Row>
        <Col md="12">
          <Card className="">
            <Card.Header>
              <Card.Title as="h4">
                Usuarios del Sistema
              </Card.Title>
              <p className="card-category">
                {"difuntoNuevo.nombre"}
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <form
                id="formUser"
                method="post"
                action={url_base + "usuarios.php"}
              >
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="border-0">#</th>
                      <th className="border-0">Tipo de Usuario</th>
                      <th className="border-0">Correo</th>
                      <th className="border-0">Clave</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((u) => (
                      <tr>
                        <td>{u.id}</td>
                        <td>{"Administrador" && u.tipo == "1" || "Usuario" && u.tipo == "2"}</td>
                        <td>{u.nombre}</td>
                        <td>{"••••••"}</td>
                        <td>
                          <td className="text-center">
                            <Button
                              className="btn btn-danger btn-sm"
                              type="button"
                              variant="danger"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Seguro desea eliminar el Usuario?"
                                  )
                                ) {
                                  peticionDeleteUser(u.id);
                                }
                              }}
                            >
                              <i className="fas fa-times"></i> Eliminar
                                          </Button>
                          </td>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <input
                          name="id_cementerio"
                          type="text"
                          defaultValue="1"
                          className="form-control d-none"
                          readOnly
                        />
                        <input
                          name="METHOD"
                          type="text"
                          defaultValue="POST"
                          className="d-none"
                        />
                      </td>
                      <td>
                        <select name="tipo" className="form-control new-val">
                          <option value="1">Administrador</option>
                          <option value="2">Usuario</option>
                        </select>
                      </td>
                      <td>
                        <input
                          name="nombre"
                          type="email"
                          className="form-control new-val"
                        />
                      </td>
                      <td>
                        <input
                          name="clave"
                          type="password"
                          className="form-control new-val"
                        />
                      </td>
                      <td className="text-center">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-506045838">
                              Guardar
                            </Tooltip>
                          }
                        >
                          <Button
                            className="btn btn-success btn-sm"
                            type="button"
                            variant="dark"
                            onClick={() => peticionPostUser()}
                          >
                            <i class="far fa-save"></i> Guardar
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
}

export default Users;
